export const TiempoEspera = ({ value }) => {
    console.log(value);
    const FechaObj = new Date(new Date(value).getTime() + 2700000);

    const Fecha = FechaObj.getHours() + ":" + FechaObj.getMinutes() + " hrs."

    return (
        <>
            <div className="alert alert-danger" role="alert" >
                <h4>
                    Se ha hecho un registro recientemente, para registrar otra asistencia debe esperar hasta las {Fecha}
                </h4>
            </div>
        </>
    )
}
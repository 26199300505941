import React, { useState } from 'react';
import { RegistrarAsistencia } from './RegistrarAsistencia.js';
import Html5QrcodePlugin from './Html5QrcodePlugin.jsx'
import ResultContainerPlugin from './ResultContainerPlugin.jsx'
import { LeyendaRegistro } from '../Componentes/LeyendaRegistro.js';

//import HowToUse from './HowToUse.jsx'

const horaRender =  new Date();
let timestamp;

/** Token DINÁMICO - MODIFICAR */
//const token = "bc3d6d9ee482525d0ae17b28b7fd2778";


class LectorQR extends React.Component {

    
    
    constructor(props) {
        super(props);
        this.state = {
            decodedResults: []
        }

        // This binding is necessary to make `this` work in the callback.
        this.onNewScanResult = this.onNewScanResult.bind(this);
        //console.log(this.onNewScanResult);

        //timestamp = new Date();
    }

    


    render() {

        if(horaRender - timestamp > 600000 || isNaN(horaRender - timestamp)){
            return (
                <div className="App">
                    <section className="App-section">
                        {/*<div className="App-section-title"> Html5-qrcode React demo</div>*/}
                        <br />
                        <br />
                        <br />
                        <Html5QrcodePlugin
                            fps={10}
                            qrbox={250}
                            disableFlip={false}
                            qrCodeSuccessCallback={this.onNewScanResult} />
                        <ResultContainerPlugin results={this.state.decodedResults} />
                        
                    </section>
                </div>
            );
        }
        else {
            console.log(horaRender);
            console.log(timestamp);
            console.log(isNaN( horaRender - timestamp) );
            setTimeout(function () {
                
                window.location.reload();
            }, 300000)
            return (
                <LeyendaRegistro />
            )
        }


        
    }




    onNewScanResult(decodedText, decodedResult) {
        console.log(
            "App [result]", decodedResult);

            console.log(decodedResult);
            /** REGISTRO DE ASISTENCIA */

            /** CONSULTAR TOKEN DESDE BASE DE DATOS, TABLA: autobus 
             * id_autobus
             * token
            */
            const token = decodedResult.decodedText;
            timestamp = new Date();
            /** OBTENER EL ID DEL AUTOBUS SEGÚN EL TOKEN LEIDO POR EL QR */
            fetch(`https://becatransportecuitzeo2021-2024.com/api-transporte/autobus?select=id_autobus,token&linkTo=token&equalTo=${token}&orderBy=1&orderMode=ASC`,{
                method: 'GET'
            }).then(RespuestaRaw => RespuestaRaw.json())
                .then(autobusResultado =>{
                    console.log(autobusResultado)

                    if(autobusResultado.status === "200"){
                        console.log("Tiene Que hacer el registro");
                       
                        /** PASAR EL ID COMO PARAMETRO
                         * RegistrarAsistencia(id_autobus);
                         */
                       
                        RegistrarAsistencia(autobusResultado.results[0].id_autobus);
                        
                    }
                    else {
                        alert("Codigo QR No valido");
                        
                    }
                })
           
            //

        // let decodedResults = this.state.decodedResults;
        // decodedResults.push(decodedResult);
        this.setState((state, props) => {
            state.decodedResults.push(decodedResult);
            return state;
        });
    }


   
   

}

export default LectorQR;
